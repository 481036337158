import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private auth: AuthenticationService,
    private navCtrl: NavController
  ) { }

  canActivate(): boolean {
    //return true;
    if (this.auth.isAuthenticated()) {
      return true;
    }
    else {
      this.navCtrl.navigateRoot('/login');
      return false;
    }
  }
}
