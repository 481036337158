import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';

import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authState = new BehaviorSubject(false);
  token: string;
  info: any;

  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    private router: Router,
    private storage: StorageService
  ) {
    this.platform.ready().then(() => {
      this.setAuthState();
    });
  }

  async setAuthState() {
    await this.storage.getObject('USER_INFO').then((res: any) => {
      if (res) {
        this.authState.next(true);
      }
    });
  }

  async signIn(loginData: any) {
    await this.storage.setObject('USER_INFO', loginData).then(() => {
      this.authState.next(true);
      this.navCtrl.navigateRoot('');
    });
  }

  async signOut() {
    await this.storage.removeItem('USER_INFO').then(() => {
      this.authState.next(false);
    });
  }

  isAuthenticated() {
    return this.authState.value;
  }

}
